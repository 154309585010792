import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
  
export const NavLink = styled(Link)`
    position: relative;
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 0.5rem 0;
    transition: all 0.5s;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
    
    &::after {
        content: "";
        position: absolute;
        inset: auto auto 0 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background-color: #fff;
        transition: all 0.5s;

        @media screen and (max-width: 1000px) {
            inset: auto auto -2px 0;
            transform: none;
            z-index: 1;
        }
    }

    &:hover {
        &::after {
            width: 90%;

            @media screen and (max-width: 1000px) {
                width: 75px;
            }
        }
    }

    &.active {
        color: #009fe3;

        &::after {
            width: 90%;
            background-color: #009fe3;

            @media screen and (max-width: 1000px) {
                width: 75px;
            }
        }
    }
`;
 
export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    transition: all 0.5s;

    @media screen and (max-width: 1000px) {
        &.menu-hidden {
            left: -100%;
        }
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        padding: 4rem 0rem 0 2rem;
        width: 320px;
        background-color: #0e0f18;

        &::before {
            
        }
    }
`;

export const NavSubMenu = styled.div`
    position: absolute;
    left: 0;
    top: calc(100% + 15px); /* Adjust this value as needed */
    display: flex;
    flex-direction: column;
    align-items: start;
    transition: all 0.5s;

    @media screen and (max-width: 1000px) {
        position: relative;
        top: 10px;
    }
`;