function NotFound(props) {
    const websiteData = props.data;
    const baseUrl = window.location.origin;
    let paginaTitel = "";
    let paginaUrl = "";
    if (Array.isArray(websiteData.siteworkcms)) {
        websiteData.siteworkcms.forEach((page) => {
            if (page.id === 1) {
                // Extract values from the object
                paginaTitel = page.item1;
                paginaUrl = page.paginaurl;
            }
        });
    }
    return (
        <main className="App-Over_ons main pt-6 px-6">
            <div className="container mx-auto">
                <h1>Pagina niet gevonden</h1>
                <p className="pb-2">Deze pagina is helaas niet beschikbaar op deze site.</p>
                <hr className="pb-4" />
                <p>Keer terug naar <a className="transition-all duration-500 ml-2 px-6 py-2 rounded-md bg-primary hover:bg-secondary hover:text-white" href={baseUrl + paginaUrl} >{paginaTitel}</a></p>
            </div>
        </main>
    );
}
export default NotFound; // Export the Over ons component