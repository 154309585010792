// Imports
import React, { lazy, Suspense, useState, useEffect } from "react";
import { NavLink, NavMenu, NavSubMenu } from "./NavbarElements";
import logo from '.././sitework_logo.svg';
import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SliderBlock = lazy(() => import('./blocks/slider'));

// Functions
const Navbar = (props) => {
    const baseUrl = window.location.origin;
    const websiteData = props.data;
    
    // Get current location using useLocation
    const location = useLocation();
    const [pageUrl, setPageUrl] = useState(location.pathname.slice(baseUrl.length));
    const [paginaID, setPaginaID] = useState("");
    const [showSubMenu, setShowSubMenu] = useState(null);

    useEffect(() => {
        setPageUrl(location.pathname); // Update pageUrl when location changes
    }, [location]);

    useEffect(() => {
        // Find paginaID based on pageUrl
        for (let i = 0; i < websiteData.siteworkcms.length; i++) {
            // if(location.pathname === "/") {
            //     setPageUrl("home");
            // }
            if (websiteData.siteworkcms[i].paginaurl === pageUrl) {
                setPaginaID(websiteData.siteworkcms[i].id);
                break; // Exit the loop once the item is found
            }
        }
    }, [pageUrl, websiteData, paginaID]); // Re-run when pageUrl or websiteData.siteworkcms changes

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleNavLinkHover = (paginaId) => {
        setTimeout(() => {
            setShowSubMenu(paginaId);
        }, 500); // Delay showing the submenu by 200 milliseconds
    };

    const handleNavSubLinkLeave = () => {
        setShowSubMenu(null);
    };

    // Function to handle mouse enter on NavLink
    const handleNavSubLinkHover = (paginaId) => {
        setShowSubMenu(paginaId);
    };

    return (
        <>
        <header className="App-header">
            <div id="header" className="bg-secondary transition-all duration-500 px-6 z-[60] fixed top-0 left-0 w-full">
                <nav className="container mx-auto h-[110px] transition-all duration-500 flex items-center justify-between">
                    <div className="left">
                        <a href={baseUrl} aria-label="Sitework B.V. logo">
                            <LazyLoadImage
                                aria-label="Sitework B.V. logo"
                                alt="Sitework B.V. logo"
                                effect="blur"
                                src={logo}
                                height={"48px"}
                                width={"280px"} />
                        </a>
                    </div>
                    <div className="right">
                        <button className="ml:hidden text-white" title="Open menu" aria-label="Open menu" tabIndex={"-1"} onClick={toggleMenu}>
                            <svg
                                className="w-8 h-8 cursor-pointer"
                                fill="#fff"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                ></path>
                            </svg>
                        </button>
                        <NavMenu className={`${isOpen ? "!flex" : "menu-hidden"} ml:flex ml:gap-2`}>
                            <svg
                                onClick={toggleMenu}
                                className="w-8 h-8 ml:hidden absolute top-6 right-6 cursor-pointer"
                                fill="#fff"
                                stroke="#fff"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                            {websiteData.siteworkcms.map((pagina, index) => (
                                pagina.status === "Actief" && pagina.keuze1 === "hoofdmenu" ? (
                                    <div key={index} className="hoofdItem">
                                        <NavLink 
                                            to={pagina.paginaurl} 
                                            className={'tracking-wider font-medium'} 
                                            onMouseEnter={() => handleNavLinkHover(pagina.id)} 
                                            activeStyle
                                        >
                                            {pagina.item1}
                                        </NavLink>
                                        {showSubMenu === pagina.id && (
                                            <NavSubMenu>
                                                {websiteData.siteworkcms.map((subPagina, subIndex) => (
                                                    subPagina.hoofdid === pagina.id && subPagina.keuze1 === "submenu" ? (
                                                        <NavLink 
                                                            key={subIndex} 
                                                            to={subPagina.paginaurl} 
                                                            className={'tracking-wider subItem font-medium'} 
                                                            onMouseEnter={() => handleNavSubLinkHover(pagina.id)} 
                                                            onMouseLeave={handleNavSubLinkLeave} 
                                                            activeStyle
                                                        >
                                                            {subPagina.item1}
                                                        </NavLink>
                                                    ) : null
                                                ))}
                                            </NavSubMenu>
                                        )}
                                    </div>
                                ) : null
                            ))}
                        </NavMenu>
                        <div className={`transition-all duration-500 ${isOpen ? "menu-open" : ""}`}></div>
                    </div>
                </nav>
            </div>
            <Suspense fallback={<div>Bezig met slider inladen...</div>}>
                <SliderBlock pageID={paginaID} data={websiteData}/>
            </Suspense>
        </header>
        </>
    );
};

export default Navbar;
