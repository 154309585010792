import './App.css';
import './scrolled.css';
import './fa/css/all.min.css';
import './typography.css';
import Navbar from './components/Navbar';
import Footer from './components/footer';
import WoningPagina from "./components/detail-pagina";
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import jsonData from './website_db.json';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/startpagina";
import Vervolgpagina from "./pages/vervolgpagina";
import NotFound from "./pages/pagina-niet-gevonden";
function App() {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
      <div id="root-inner" className={scrolled ? 'scrolled' : ''}>
        <Helmet>
          <title>Realworks | Sitework B.V.</title>
        </Helmet>
        <Router>
          <Navbar data={jsonData} />
          <Routes>
            <Route exact path="/" element={<Home data={jsonData} />} />
            <Route path="/woning/:woning" element={<WoningPagina data={jsonData} />} />
            <Route
                path={"/:page"}
                element={<Vervolgpagina data={jsonData}/>}
            />
            <Route
                path={"/team/:teamlid"}
                element={<Vervolgpagina data={jsonData}/>}
            />
            <Route path="*" element={<NotFound data={jsonData} />} />
            {/* <Route path="/api" exact component={Stagereact_db} /> */}
          </Routes>
          <Footer data={jsonData} />
        </Router>
      </div>
  );
}
export default App;