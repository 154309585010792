import React from 'react';
import { useParams } from 'react-router-dom';
import DetailApi from './detail-info';


function Woning_pagina(props) {
  const websiteData = props.data;
  let { woning } = useParams();
  const parts = woning.split('_');

  // Assuming the format is "/woning/:param1-:param2-:param3-:param4"
  const plaats = parts[0]; // Replace hyphens with spaces
  const straat = parts[1];
  const huisnr = parts[2];
  const id = parts[3];
    
  return (
    <main className="App-detail-woning main pt-6 px-2 sm:px-6">
        <div className="container mx-auto">
            <DetailApi plaats={plaats} straat={straat} huisnr={huisnr} id={id} data={websiteData}/>
        </div>
    </main>
    );
}

export default Woning_pagina;