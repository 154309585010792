import React, { useState, useEffect } from 'react';
import jsonData from '../woning.json'; // Import JSON data directly

function Api() {
  const [data, setData] = useState(null); // Initialize data as null
  const [error, setError] = useState(null);
  const baseUrl = window.location.origin;
  const nieuwDate = new Date(Date.now() - 1209600000);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('URL_TO_YOUR_API_ENDPOINT');
        // Replace the URL_TO_YOUR_API_ENDPOINT with your actual API endpoint
        // const jsonData = await response.json();
        setData(jsonData.results); // Set data to jsonData.results
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, []); // Empty dependency array to fetch data only once

  return (
    <div className='container mx-auto'>
      {error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-6'>
          {data && data.actief ? (
            <a key={data.id} className='hover:translate-x-[10px] hover:translate-y-[-10px] transition-all duration-500 h-full w-full flex flex-col rounded-md shadow-lg bg-white' href={baseUrl + '/woning/' + data.adres.plaats.toLowerCase() + '_' + data.adres.straat.toLowerCase() + '_' + data.adres.huisnummer + '_' + data.id}>
              <div id="img-container" className='relative w-full h-full aspect-video'>
                {new Date(data.diversen.diversen.invoerdatum) > nieuwDate ? (
                  <span className="label-detailpagina absolute top-0 right-0 font-semibold nieuw px-6 p-4 z-20 rounded-tr-xl rounded-bl-xl bg-secondary text-white">Nieuw</span>
                ) : (
                  <span className="label-detailpagina absolute top-0 right-0 font-semibold status px-6 p-4 z-20 rounded-tr-xl rounded-bl-xl bg-secondary text-white capitalize">{data.financieel.overdracht.status}</span>
                )}
                <ul className='absolute top-0 left-0 h-full w-full'>
                  {data.media && data.media.filter(item => item.vrijgave && item.soort === "HOOFDFOTO").map((image, index) => (
                    <li key={index} className="image h-full w-full">
                      <img className='h-full w-full object-cover rounded-t-lg' src={image.link + "&width=500&height=330&resize=5&size=500x330"} type={image.mimetype} alt={image.omschrijving} />
                    </li>
                  ))}
                </ul>
              </div>
              <div id="woning-content" className='relative px-6 py-6'>
                <h3 className='capitalize pt-2 px-8 bg-white absolute left-0 top-[-40px] md:top-[-50px] rounded-tr-xl'>{data.adres.plaats.toLowerCase()}</h3>
                <h4>{data.adres.straat}&nbsp;{data.adres.huisnummer}{', ' + data.adres.huisnummertoevoeging}</h4>
                {data.financieel.overdracht.koopprijs > 0 ? (
                  <p className='font-semibold text-xl text-primary'>&euro; {Number(data.financieel.overdracht.koopprijs).toLocaleString()},-</p>
                ) : (
                  <p className='font-semibold text-xl text-primary'>
                    &euro; {Number(data.financieel.overdracht.huurprijs).toLocaleString()},- per maand
                  </p>
                )}
              </div>
            </a>
          ) : (
            <p>Geen woningen om te weergeven.</p>
          )}
        </div>
      )}
    </div>
  );
  
}

export default Api;
