// Imports
import Api from '.././components/api';
import Blocks from "../components/blocks/blocks.js";
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function Vervolgpagina(props) {
    const baseUrl = window.location.origin;
    const websiteData = props.data;
    const { teamlid, page } = useParams(); 
    const location = useLocation();
    const [pageUrl, setPageUrl] = useState(location.pathname.slice(baseUrl.length));
    const [paginaID, setPaginaID] = useState("");
    useEffect(() => {
        setPageUrl(location.pathname); // Update pageUrl when location changes
    }, [location]);
    useEffect(() => {
        // Find paginaID based on pageUrl
        for (let i = 0; i < websiteData.siteworkcms.length; i++) {
            // if(location.pathname === "/") {
            //     setPageUrl("home");
            // }
            if (websiteData.siteworkcms[i].paginaurl === pageUrl) {
                setPaginaID(websiteData.siteworkcms[i].id);
                break; // Exit the loop once the item is found
            }
        }
    }, [pageUrl, websiteData, paginaID]); // Re-run when pageUrl or websiteData.siteworkcms changes
    let id = "";
    let titel = "";
    let item3 = "";
    let item4 = "";
    let keuze1 = "";
    let tekst = "";
    if (teamlid !== "" && teamlid !== undefined && teamlid !== null) {
        if (Array.isArray(websiteData.siteworkcms)) {
            websiteData.siteworkcms.forEach((vervolgpagina) => {
                if (vervolgpagina.paginaurl === '/' + teamlid) {
                    // Extract values from the object
                    id = vervolgpagina.id;
                    titel = vervolgpagina.item1;
                    item3 = vervolgpagina.item3;
                    item4 = vervolgpagina.item4;
                    keuze1 = vervolgpagina.keuze1;
                    tekst = vervolgpagina.tekst;
                }
            });
        }
    } else if (page !== "") {
        if (Array.isArray(websiteData.siteworkcms)) {
            websiteData.siteworkcms.forEach((vervolgpagina) => {
                if (vervolgpagina.paginaurl === '/' + page) {
                    // Extract values from the object
                    id = vervolgpagina.id;
                    titel = vervolgpagina.item1;
                    item3 = vervolgpagina.item3;
                    item4 = vervolgpagina.item4;
                    keuze1 = vervolgpagina.keuze1;
                    tekst = vervolgpagina.tekst;
                }
            });
        }
    } else {
        if (Array.isArray(websiteData.siteworkcms)) {
            websiteData.siteworkcms.forEach((vervolgpagina) => {
                if (vervolgpagina.paginaurl === pageUrl) {
                    // Extract values from the object
                    id = vervolgpagina.id;
                    titel = vervolgpagina.item1;
                    item3 = vervolgpagina.item3;
                    item4 = vervolgpagina.item4;
                    keuze1 = vervolgpagina.keuze1;
                    tekst = vervolgpagina.tekst;
                }
            });
        }
    }
    return (
        <>
        <Helmet>
            <title>{titel} - Sitework B.V.</title>
        </Helmet>
            <main className="App-vervolgpage main pt-6 px-6">
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 md:gap-8 lg:gap-12">
                    <article  className='md:col-span-3 w-full lg:w-4/5'>
                        <h1>{titel}</h1>
                        <>
                            {keuze1 === "team" ? (
                                <>
                                    {item4 !== "" ? (
                                        <h4 className="!m-0 my-1">Functie: {item4}</h4>
                                    ) : (
                                        null
                                    )}
                                    {item3 !== "" ? (
                                        <h4  className="!m-0 my-1 !mb-4">E-mail: <a className="text-primary" href={"mailto:" + item3}>{item3}</a></h4>
                                    ) : (
                                        null
                                    )}
                                </>
                            ) : (
                                null
                            )}
                        </>
                        <p>{tekst}</p>
                    </article>
                    <aside className='md:col-span-2'>
                        <div className="zijkant-images">
                            <div className='img-zijkant'>
                                {websiteData.sitework_img && websiteData.sitework_img.length > 0 ? (
                                    <div id="afbeeldingen-zijkant" className="grid grid-cols-2 gap-4">
                                        {websiteData.sitework_img.map((afbeelding, index) => (
                                            afbeelding.cms_id === id && afbeelding.afbsoort === "uitgelicht" ? (
                                                <a key={index} data-fancybox="image" href={baseUrl + "/img/" + afbeelding.naam + "." + afbeelding.ext} aria-label={afbeelding.bijschrift} className="afbeelding-zijkant rounded-md overflow-hidden">
                                                    <LazyLoadImage
                                                        aria-label={afbeelding.bijschrift}
                                                        alt={afbeelding.bijschrift}
                                                        effect="blur"
                                                        src={baseUrl + "/img/" + afbeelding.naam + "." + afbeelding.ext}
                                                        height={afbeelding.height}
                                                        width={afbeelding.width} />
                                                </a>
                                            ) : null
                                        ))}
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>
                        </div>
                    </aside>
                </div>
                {id === 2 ? (
                    <Api />
                ) : (null)}
                <Blocks data={websiteData}/>
            </main>
        </>
    );
}
export default Vervolgpagina; // Export the Contact component