import React, { useState, useEffect } from 'react';
import jsonData from '.././woning.json'; // Import JSON data directly
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function nl2br(str) {
  return { __html: str.replace(/(?:\r\n|\r|\n)/g, '<br>') };
}

function Detail_Api(props) {
  const [data, setData] = useState([]); // Initialize data as an empty array
  const [error, setError] = useState(null);
  // const plaats = props.plaats;
  // const straat = props.straat;
  // const huisnr = props.huisnr;
  // const id = props.id;
  const websiteData = props.data;
  const nieuwDate = new Date(Date.now() - 1209600000);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('https://api.sampleapis.com/coffee/hot/' + coffeeID);
        // if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }
        // const jsonInfo = await jsonData.json();
        
        // Wrap the returned object in an array if necessary    
        setData(jsonData.results);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, []); // Include coffeeID in the dependency array to refetch data when it changes
  // style
  const itemsStyle = "w-full grid grid-cols-2 gap-4 py-4 border-solid border-b border-secondary";
  const itemHead = "font-semibold text-left text-lg sm:text-xl";
  const itemValue = "font-medium text-right sm:text-left text-lg sm:text-xl text-primary";

  return (
    <div>
      {error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div id="woning-container" className="py-6 mt-24 overflow-hidden">
          {data && data.actief ? (
            <div key={data.id} className='grid grid-cols-1 xl:grid-cols-3 gap-y-6 lg:gap-6'>
              <div id="woning-kenmerken" className='shadow-lg rounded-xl p-6 sm:p-8 cols-span-1 xl:row-start-1	row-start-2'>
                <h3 className='relative pb-1 w-fit after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-[150%] after:h-0.5 after:bg-primary'>
                  Kenmerken
                </h3>
                <ul>
                  {data.financieel.overdracht.koopprijs > 0 ? (
                    <li className={itemsStyle}>
                      <dt className={itemHead}>Vraagprijs:</dt>
                      <dd className={itemValue}>&euro; {Number(data.financieel.overdracht.koopprijs).toLocaleString()},-</dd>
                    </li>
                  ) : (
                    <li className={itemsStyle}>
                      <dt className={itemHead}>Huurprijs</dt>
                      <dd className={itemValue}>&euro; {Number(data.financieel.overdracht.huurprijs).toLocaleString()},- per maand</dd>
                    </li>
                  )}
                  {data.algemeen.appartementsoort != null ? (
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Soort woonhuis</dt>
                    <dd className={itemValue + " lowercase"}>{data.algemeen.appartementsoort}</dd>
                  </li>
                  ) : ( null )}
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Status</dt>
                    <dd className={itemValue + " lowercase"}>{data.financieel.overdracht.status}</dd>
                  </li>
                  {data.financieel.overdracht.aanvaarding != null ? (
                    <li className={itemsStyle}>
                      <dt className={itemHead}>Aanvaarding</dt>
                      <dd className={itemValue + " lowercase"}>
                        {data.financieel.overdracht.aanvaarding.split('_').map((part, index) => (
                          <span key={index}>{part}&nbsp;</span>
                        ))}
                      </dd>
                    </li>
                  ) : (null)}
                  <li className='w-full grid grid-cols-2 gap-4 py-4 border-solid border-b-2 border-primary'>
                    <dt className='font-semibold text-2xl text-primary'>Bouw</dt>
                    <dd className={itemValue}></dd>
                  </li>
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Bouwjaar</dt>
                    <dd className={itemValue}>{data.algemeen.bouwjaar}</dd>
                  </li>
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Woonoppervlakte</dt>
                    <dd className={itemValue}>{data.algemeen.woonoppervlakte + " m²"}</dd>
                  </li>
                  {data.algemeen.totaleKadestraleOppervlakte > 0 ? (
                    <li className={itemsStyle}>
                      <dt className={itemHead}>Perceeloppervlakte</dt>
                      <dd className={itemValue}>{data.algemeen.totaleKadestraleOppervlakte + " m²"}</dd>
                    </li>
                  ) : ( null )}
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Inhoud</dt>
                    <dd className={itemValue}>{data.algemeen.inhoud + " m³"}</dd>
                  </li>
                  {data.detail.etages[0].aantalKamers > 0 ? (
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Aantal kamers</dt>
                    <dd className={itemValue}>{data.detail.etages[0].aantalKamers}</dd>
                  </li>
                  ) : ( null )}
                  {data.detail.etages[0].aantalSlaapkamers > 0 ? (
                  <li className={itemsStyle}>
                    <dt className={itemHead}>Aantal slaapkamers</dt>
                    <dd className={itemValue}>{data.detail.etages[0].aantalSlaapkamers}</dd>
                  </li>
                  ) : ( null )}
                </ul>
              </div>
              <div id="img-container" className='relative md:col-span-2 flex flex-col gap-4'>
                {new Date(data.diversen.diversen.invoerdatum) > nieuwDate ? (
                  <span className="label-detailpagina absolute top-0 right-0 font-semibold nieuw px-6 p-4 z-20 rounded-tr-xl rounded-bl-xl bg-secondary text-white">Nieuw</span>
                ) : (
                  <span className="label-detailpagina absolute top-0 right-0 font-semibold status px-6 p-4 z-20 rounded-tr-xl rounded-bl-xl bg-secondary text-white capitalize">{data.financieel.overdracht.status}</span>
                )}
                {data.media && data.media.filter(item => item.vrijgave && item.soort === "HOOFDFOTO").map((image, index) => (
                  <div key={index} className="hoofd-image h-full w-full">
                    <img className='h-full w-full object-cover rounded-lg' src={image.link + "&width=1000&height=648&resize=5&size=936x593"} type={image.mimetype} alt={image.omschrijving} />
                  </div>
                ))}
                <div id="img-galerij" className="h-full">
                  <Slider 
                    dots={false} 
                    infinite={true} 
                    speed={500} 
                    responsive={[
                      {
                          breakpoint: 350, // Adjust breakpoint as needed
                          settings: {
                              dots: false,
                              autoplay: true,
                              slidesToShow: 2, // Show 2 logos on smaller screens
                              slidesToScroll: 1,
                          },
                      },
                      {
                          breakpoint: 768, // Adjust breakpoint as needed
                          settings: {
                              dots: false,
                              autoplay: true,
                              slidesToShow: 3, // Show 3 logos on medium screens
                              slidesToScroll: 1,
                          },
                      },
                      {
                          breakpoint: 2600, // Adjust breakpoint as needed
                          settings: {
                              slidesToShow: 5, // Show 3 logos on medium screens
                              slidesToScroll: 1,
                          },
                      },
                  ]}
                    >
                    {data.media.map((afbeelding, index) => (
                      afbeelding && afbeelding.vrijgave ? (
                        <a key={index} className='rounded-md overflow-hidden' data-fancybox="groupSmall" href={afbeelding.link + "&resize=4"}>
                          <img src={afbeelding.link + "&width=1000&height=600&resize=5&size=936x593"} className="w-full h-full" type={afbeelding.mimetype} alt={afbeelding.omschrijving} />
                        </a>
                      ) : (
                        null
                      )
                    ))}
                  </Slider>
                </div>
              </div>
              <div id="woning-buttons" className='md:col-span-3'>
                <div id="popup-buttons" className="py-4 flex flex-col sm:flex-row gap-4 sm:overflow-x-auto border-b-2 border-t-2 border-primary">
                  {data.media.filter(item => item.soort === 'FOTO' || item.soort === 'HOOFDFOTO').map((rowallefotos, index) => {
                    const isFirst = index === 0;
                    return (
                      <a
                        key={index}
                        className={`btn px-8 py-3 text-xl w-full text-center sm:w-fit min-w-[275px] bg-white text-secondary border-secondary border rounded-lg transition-all duration-500 ${!isFirst && '!hidden'} hover:bg-white hover:shadow-lg hover:text-primary hover:border-white`}
                        data-fancybox="group1"
                        href={`${rowallefotos.link}&resize=4`}
                        data-caption={rowallefotos.title}
                      >
                        <img
                          className="images"
                          src={`${rowallefotos.link}`}
                          width="100%"
                          alt={rowallefotos.omschrijving}
                        />
                        <i className="fal fa-images" /> Foto overzicht ({data.media.length})
                      </a>
                    ); // 33 is einde
                  })}
                  {data.media.filter(item => item.soort === 'VIDEO').map((video, index) => (
                    <a key={index} className="btn px-8 py-3 text-xl bg-white text-secondary border-secondary border rounded-lg transition-all duration-500 hover:bg-white hover:shadow-lg hover:text-primary hover:border-white" href={`https://www.youtube.com/watch?v=${video.link}`} data-fancybox="video">
                      <i className="fas fa-photo-video mr-2"></i> Video
                    </a>
                  ))}
                  {data.media.filter(item => item.soort === 'DOCUMENT').map((doc, index) => (
                    <a key={index} href={`${doc.link}`} target="_blank" rel="noreferrer" className="btn px-8 py-3 text-xl bg-white text-secondary border-secondary border rounded-lg transition-all duration-500 hover:bg-white hover:shadow-lg hover:text-primary hover:border-white">
                      <i className="fas fa-file mr-2"></i> Brochure downloaden
                    </a> 
                  ))}
                  {data.media.filter(item => item.soort === 'PLATTEGROND').map((plat, index) => (
                    <a key={index} href={`${plat.link}`} target="_blank" rel="noreferrer" className="btn px-8 py-3 text-xl bg-white text-secondary border-secondary border rounded-lg transition-all duration-500 hover:bg-white hover:shadow-lg hover:text-primary hover:border-white">
                      <i className="fas fa-map-marker-alt mr-2"></i> Bekijk plattegrond
                    </a>
                  ))}
                </div>
              </div>
              <div className="md:col-span-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12" id="content-inner">
                <div className="content-col-left woning-detail col-span-2">
                    <h1 className="mb-8">
                      {data.adres.straat + " " + data.adres.huisnummer + ' ' + data.adres.huisnummertoevoeging + ", "}
                        <small>{data.adres.plaats}</small>
                    </h1>
                    <div dangerouslySetInnerHTML={nl2br(data.teksten.aanbiedingstekst)} />
                </div>
                <div className="content-col-right col-span-2 lg:col-span-1">

                    <div id="contactpersoon" className="sticky top-[6rem] rounded-bl-2xl bg-secondary px-8 pt-8 lg:mt-24 grid grid-cols-1">
                        <div className="left flex justify-between flex-col text-white">
                            <div className="top flex flex-col">
                                <h4 className="font-bold text-white leading-none mb-4 text-lg">Wilt u meer weten over de woning?
                                </h4>
                                <span className="bold text-xl">Neem contact op</span>
                                <span className='flex flex-col gap-1 mt-2'>
                                  <a href={"mailto:" + websiteData.sitework_settings[0].websiteemail} className="text-primary hover:underline">{websiteData.sitework_settings[0].websiteemail}</a>
                                  <a href={"tel:" + websiteData.sitework_settings[0].websitetelnr} className="text-primary hover:underline">{websiteData.sitework_settings[0].websitetelnr}</a>
                                </span>
                            </div>
                            <a href="/contact" className="btn inline-flex self-start mt-6 mb-8 px-6 py-2 bg-primary/[0.3] rounded-md rounded-lg hover:bg-primary/[0.7] transition-all duration-500">Contact</a>
                        </div>

                    </div>
                </div>
            </div>
            </div>
          ) : (
            <p>This data is not active.</p>
          )}
        </div>
      )}
    </div>
  );
  // return (
  //   <div>
  //     <p>Plaats: {plaats}</p>
  //     <p>Straat: {straat}</p>
  //     <p>Huis nr.: {huisnr}</p>
  //     <p>ID: {id}</p>
  //   </div>
  // );
}

export default Detail_Api;
