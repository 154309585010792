import React, { lazy, Suspense, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

const FotoLinksTekstRechts = lazy(() => import('./Foto-links-tekst-rechts'));
const TekstLinksFotoRechts = lazy(() => import('./Tekst-links-foto-rechts'));
const CategorieOverzichtDrie = lazy(() => import('./Categorie-overzicht-drie'));
const CategorieOverzichtLaad = lazy(() => import('./Categorie-overzicht-laad'));
const ContactForm = lazy(() => import('./Contact-form'));
const TitelSubtitel = lazy(() => import('./Titel-subtitel'));
const Galerij = lazy(() => import('./Galerij'));
const GalerijGrid = lazy(() => import('./Galerij-grid'));
const GoogleMaps = lazy(() => import('./Google-maps'));
const LogoSlider = lazy(() => import('./Logo-slider'));
const TeamOverzicht = lazy(() => import('./Team-overzicht'));
const TekstTweeKolommen = lazy(() => import('./Tekst-twee-kolommen'));
const Video = lazy(() => import('./Video'));

function Blocks(props) {
    const baseUrl = window.location.origin;
    const websiteData = props.data;
    const location = useLocation();
    const [pageUrl, setPageUrl] = useState(location.pathname.slice(baseUrl.length));
    const [paginaID, setPaginaID] = useState("");
    useEffect(() => {
        setPageUrl(location.pathname); // Update pageUrl when location changes
    }, [location]);
    useEffect(() => {
        // Find paginaID based on pageUrl
        for (let i = 0; i < websiteData.siteworkcms.length; i++) {
            if (websiteData.siteworkcms[i].paginaurl === pageUrl) {
                setPaginaID(websiteData.siteworkcms[i].id);
                break; // Exit the loop once the item is found
            }
        }
    }, [pageUrl, websiteData]);
    websiteData.sitework_blocks.sort((a, b) => parseInt(a.volgorde) - parseInt(b.volgorde));
    const contentComponents = [];
    for (let i = 0; i < websiteData.sitework_blocks.length; i++) {
        const block = websiteData.sitework_blocks[i];
        if (block.cms_id === paginaID) {
            const pageCmsId = block.cms_id;
            const pageBlockId = block.block_id;
            const pageBlocksTableID = block.id;
            switch (pageBlockId) {
                case 2:
                    contentComponents.push(<FotoLinksTekstRechts key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 3:
                    contentComponents.push(<TekstLinksFotoRechts key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 4:
                    contentComponents.push(<CategorieOverzichtDrie key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 5:
                    contentComponents.push(<TekstTweeKolommen key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 6:
                    contentComponents.push(<CategorieOverzichtLaad key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 7:
                    contentComponents.push(<Galerij key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 8:
                    contentComponents.push(<Video key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 9:
                    contentComponents.push(<ContactForm key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 10:
                    contentComponents.push(<GoogleMaps key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 11:
                    contentComponents.push(<LogoSlider key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 12:
                    contentComponents.push(<GalerijGrid key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 13:
                    contentComponents.push(<TeamOverzicht key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                case 14:
                    contentComponents.push(<TitelSubtitel key={pageBlocksTableID} id={pageBlocksTableID} cms_id={pageCmsId} data={websiteData} />);
                    break;
                default:
                    break;
            }
        }
    }
    if (contentComponents.length === 0) {
        contentComponents.push("");
    }
    return (
        <div className="container mx-auto">
            <Suspense fallback={<div>Blokken aan het inladen...</div>}>
                {contentComponents}
            </Suspense>
        </div>
    );
}
export default Blocks; // Export the Blocks component