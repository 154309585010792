import Blocks from "../components/blocks/blocks.js";
import { Helmet } from 'react-helmet';
function Home(props) {
    const websiteData = props.data;
    return (
        <>
            <Helmet>
                <title>Home - Sitework B.V.</title>
            </Helmet>
            <main className="App-home main pt-6 px-6">
                <Blocks data={websiteData}/>
            </main>
        </>
        
    );
}
export default Home; // Export the Home component